<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-24">
        <strong>Forgot your password?</strong>
      </div>
      <span class="text-secondary mb-4"
        >Don't worry, we'll send you a link to your email to change your
        password.</span
      >
      <a-form class="mb-4" :form="form" @submit="handleResetPassword">
        <a-form-item>
          <a-input
            size="large"
            placeholder="Email"
            v-decorator="[
              'email',
              {
                rules: [
                  { required: true, message: 'Please input your Email!' },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-button
          size="large"
          type="primary"
          class="text-center w-100"
          html-type="submit"
        >
          <strong>Reset Password</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="kit__utils__link font-size-16">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Back to login page
      </router-link>
    </div>
  </div>
</template>
<script>
import { resetPassword } from '@/services/axios/api/auth'
import { notification } from 'ant-design-vue'
export default {
  name: 'CuiForgotPassword',
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    async handleResetPassword(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'admin')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description:
                  'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
              this.$router.push('/auth/login')
            }
            // this.submitting = false
          } catch (e) {
            // this.submitting = false
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
